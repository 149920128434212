import { ref, computed, nextTick } from 'vue';
import { apiEgpmMatTaskResultStatsArea } from '@/request/egpmMaterialApi';
import { useRoute } from 'vue-router';
import { ElLoading } from 'element-plus';

export default ({ resultStore }) => {

    const route = useRoute();
    const pageNum = ref(1);
    const pageSize = ref(10);
    const total = ref(0);
    const titleList = ref([]);
    const tableData = ref([]); // 表格数据
    const tableDataPage = computed(() => {
        return tableData.value.slice((pageNum.value - 1) * pageSize.value, pageNum.value * pageSize.value)
    })

    // 获取表格数据
    async function getTableData() {
        const loading = ElLoading.service({ text: '加载中，请稍候...' });
        try {
            const params = { ...resultStore.sreen, taskId: route.query.id }
            const { data } = await apiEgpmMatTaskResultStatsArea(params);
            titleList.value = data?.title || [];
            tableData.value = data?.items || [];
            total.value = tableData.value.length;
        } finally {
            nextTick(() => {
                loading.close();
            })
        }
    }

    // 表格搜索
    function search() {
        getTableData();
    }

    // 分页触发，先加载loading再渲染数据
    function currentChange(e) {
        const loading = ElLoading.service({ text: '加载中，请稍候...' });
        setTimeout(() => {
            pageNum.value = e;
            nextTick(() => {
                loading.close();
            })
        }, 200)
    }

    getTableData();

    return { pageNum, pageSize, total, titleList, tableDataPage, search, currentChange }
}